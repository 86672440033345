import React, { useState, useEffect } from 'react';
import _ from "lodash"
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Moment from "react-moment"
import { createIcon } from "./icon"

// helpers
import { DATE_TIME_FMT } from "../../../helpers/dates"


export const Locations = props => {

    const MAP_STYLE = process.env.REACT_APP_MAP_STYLE
    const { points, target } = props

    useEffect(() => {
        if (points.length) {
            const point = points[0]
            setCenter({
                lat: point.location.lat,
                lng: point.location.lng
            })
        }
    }, [points])

    useEffect(() => {
        if (MAP_STYLE) {
            setUrlMap(MAP_STYLE)
        }
    }, [MAP_STYLE])

    const [center, setCenter] = useState({ lat: 19, lng: -101 })
    const [urlMap, setUrlMap] = useState("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")

    return (
        <React.Fragment>
            <MapContainer
                center={center}
                zoom={7}
                // scrollWheelZoom={false}
                style={{ zIndex: 2 }}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={urlMap}
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MarkerClusterGroup
                    showCoverageOnHover={false}
                    removeOutsideVisibleBounds
                    iconCreateFunction={createIcon}
                >
                    {points.map(row => {
                        const question = row.data.find(r => r.question === target)
                        return (
                            <Marker
                                key={row.id}
                                position={{ lat: row.location.lat, lng: row.location.lng }}
                                title={_.get(question, "answer")}
                                value={1}
                            >
                                <Tooltip>
                                    <p>
                                        <strong>{_.get(question, "answer")}</strong>
                                        <br />
                                        {row.created_by && <>Creado por: <strong>{row.created_by}</strong> <br /></>}
                                        <Moment format={DATE_TIME_FMT}>
                                            {row.epicollect_created_at}
                                        </Moment>
                                    </p>
                                </Tooltip>
                            </Marker>
                        )
                    })}
                </MarkerClusterGroup>
            </MapContainer>
        </React.Fragment >
    );
}

export const GET_ALL_PROJECTS_REQUEST = 'GET_ALL_PROJECTS_REQUEST'
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS'
export const GET_ALL_PROJECTS_ERROR = 'GET_ALL_PROJECTS_ERROR'

export const GET_ALL_OPTIONS_PROJECTS_REQUEST = 'GET_ALL_OPTIONS_PROJECTS_REQUEST'
export const GET_ALL_OPTIONS_PROJECTS_SUCCESS = 'GET_ALL_OPTIONS_PROJECTS_SUCCESS'
export const GET_ALL_OPTIONS_PROJECTS_ERROR = 'GET_ALL_OPTIONS_PROJECTS_ERROR'

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR'

export const RETRIEVE_PROJECT_REQUEST = 'RETRIEVE_PROJECT_REQUEST'
export const RETRIEVE_PROJECT_SUCCESS = 'RETRIEVE_PROJECT_SUCCESS'
export const RETRIEVE_PROJECT_ERROR = 'RETRIEVE_PROJECT_ERROR'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR'

export const DESTROY_PROJECT_REQUEST = 'DESTROY_PROJECT_REQUEST'
export const DESTROY_PROJECT_SUCCESS = 'DESTROY_PROJECT_SUCCESS'
export const DESTROY_PROJECT_ERROR = 'DESTROY_PROJECT_ERROR'

import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {
	GET_ALL_PROJECTS_REQUEST,
	GET_ALL_OPTIONS_PROJECTS_REQUEST,
	CREATE_PROJECT_REQUEST,
	RETRIEVE_PROJECT_REQUEST,
	UPDATE_PROJECT_REQUEST,
	DESTROY_PROJECT_REQUEST
} from './actionTypes';

import actions from './actions';
import services from './services'


function* getAll({ payload }) {
	try {
		const response = yield call(services.getAll, payload)
		yield put(actions.getAllSuccess(response))
	} catch (error) {
		yield put(actions.getAllError(error))
	}
}

function* getAllOptions({ payload }) {
	try {
		const response = yield call(services.getAllOptions, payload)
		yield put(actions.getAllOptionsSuccess(response))
	} catch (error) {
		yield put(actions.getAllOptionsError(error))
	}
}

function* create({ payload }) {
	try {
		const response = yield call(services.create, payload)
		yield put(actions.createSuccess(response))
	} catch (error) {
		yield put(actions.createError(error))
	}
}

function* retrieve({ payload }) {
	try {
		const response = yield call(services.retrieve, payload)
		yield put(actions.retrieveSuccess(response))
	} catch (error) {
		yield put(actions.retrieveError(error))
	}
}

function* update({ payload: { id, data } }) {
	try {
		const response = yield call(services.update, id, data)
		yield put(actions.updateSuccess(response))
	} catch (error) {
		yield put(actions.updateError(error))
	}
}

function* destroy({ payload }) {
	try {
		const response = yield call(services.destroy, payload)
		yield put(actions.destroySuccess(response))
	} catch (error) {
		yield put(actions.destroyError(error))
	}
}

export function* watchProjectGetAll() {
	yield takeEvery(GET_ALL_PROJECTS_REQUEST, getAll)
}

export function* watchProgramasGetAllOptions() {
	yield takeEvery(GET_ALL_OPTIONS_PROJECTS_REQUEST, getAllOptions)
}

export function* watchProjecCreate() {
	yield takeEvery(CREATE_PROJECT_REQUEST, create)
}

export function* watchProjecRetrieve() {
	yield takeEvery(RETRIEVE_PROJECT_REQUEST, retrieve)
}

export function* watchProjecUpdate() {
	yield takeEvery(UPDATE_PROJECT_REQUEST, update)
}

export function* watchProjecDestroy() {
	yield takeEvery(DESTROY_PROJECT_REQUEST, destroy)
}

function* projectsSaga() {
	yield all([
		fork(watchProjectGetAll),
		fork(watchProgramasGetAllOptions),
		fork(watchProjecCreate),
		fork(watchProjecRetrieve),
		fork(watchProjecUpdate),
		fork(watchProjecDestroy),
	]);
}

export default projectsSaga;

import React, { useState, useEffect } from 'react';
import _ from "lodash"
import ReactEcharts from 'echarts-for-react';
import {
    Row,
    Col
} from "reactstrap"

import { color_list } from "../../../helpers/constants"


export const PieChart = props => {
    const {
        title,
        data,
        entries
    } = props

    const [options, setOptions] = useState({})

    useEffect(() => {
        let legends = []
        let dataChart = []

        _.get(data, "results", []).forEach(row => {
            const label = row.key
            const value = row.count
            legends.push(label)
            dataChart.push({ value: value, name: label })
        })

        let partialOptions = {
            toolbox: {
                show: false,
            },
            tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                data: legends,
                textStyle: {
                    color: ['#74788d']
                }
            },
            color: color_list,
            series: [
                {
                    name: 'Total',
                    type: 'pie',
                    radius: '55%',
                    center: ['50%', '75%'],
                    data: dataChart,
                    itemStyle: {
                        emphasis: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
        setOptions(partialOptions)
    }, [data])


    return (
        <React.Fragment>
            <Row>
                <Col className="text-center">
                    <h3>{title}</h3>
                    <h4>Total: <strong>{entries}</strong> registros</h4>
                </Col>
            </Row>
            <ReactEcharts
                style={{ height: "500px" }}
                option={options}
            />
        </React.Fragment>
    );
}

import React, { useEffect } from 'react'
import _ from "lodash"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Row,
    Col,
    Container,
    Card,
    CardBody
} from "reactstrap"
import moment from "moment"
// actions
import projectsActions from "../../store/projects/actions"

// components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { IconBigLoading } from "../../components/Icons"
import { PieChart, Locations } from "./components"


export let ProjectDetail = props => {

    const {
        retrieve,
        data,
        loading,
        match: { params: { slug } },
    } = props

    useEffect(() => {
        const finist_at = _.get(data, "finish_at", false)
        const now = moment()

        if (finist_at && now.isBefore(finist_at)) {
            console.log(now)
            const timer = setInterval(
                () => retrieve(slug),
                60000
            );
            return () => clearInterval(timer);
        }
        if (_.isEmpty(data))
            retrieve(slug)

    }, [data, slug, retrieve, slug]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Inicio" breadcrumbItem="Encuestas" />
                    {loading && <IconBigLoading />}
                    <React.Fragment>
                        <Row>
                            <Col className="text-center">
                                <Card>
                                    <CardBody>
                                        <h2>{_.get(data, "name", "")}</h2>
                                        <h4 className="text-muted">Total <strong>{_.get(data, "total_entries")} registros</strong></h4>
                                        <h5 className="text-muted">
                                            Con ubicación: <strong>{_.get(data, "total_entries_with_location", 0)}</strong> / Sin ubicación: <strong>{_.get(data, "total_entries_without_location", 0)}</strong>
                                        </h5>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Locations points={_.get(data, "entries", []).filter(row => row.location !== null)} target={_.has(data, "targets") && data.targets.length > 0 ? _.get(data.targets[0], "tag") : ""} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {_.get(data, "targets", []).map(row => {
                                const target = row.tag
                                let statistics = _.get(data, "statistics", []).find(st => st.data.target === target)
                                return (
                                    <Col md={12} key={row} className="mt-4">
                                        <Card>
                                            <CardBody>
                                                <PieChart
                                                    data={_.get(statistics, "data")}
                                                    title={_.get(statistics, "title")}
                                                    entries={_.get(data, "entries", []).length}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </React.Fragment>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStateToProps = state => {
    const {
        projects: {
            detail: {
                data,
                loading,
                errors
            },
        }
    } = state

    return {
        data,
        loading,
        errors,
    }
}

ProjectDetail = withRouter(connect(mapStateToProps, {
    retrieve: projectsActions.retrieveRequest,
})(ProjectDetail));

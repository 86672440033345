import {
	GET_ALL_PROJECTS_REQUEST,
	GET_ALL_PROJECTS_SUCCESS,
	GET_ALL_PROJECTS_ERROR,

	GET_ALL_OPTIONS_PROJECTS_REQUEST,
	GET_ALL_OPTIONS_PROJECTS_SUCCESS,
	GET_ALL_OPTIONS_PROJECTS_ERROR,

	CREATE_PROJECT_REQUEST,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,

	RETRIEVE_PROJECT_REQUEST,
	RETRIEVE_PROJECT_SUCCESS,
	RETRIEVE_PROJECT_ERROR,

	UPDATE_PROJECT_REQUEST,
	UPDATE_PROJECT_SUCCESS,
	UPDATE_PROJECT_ERROR,

	DESTROY_PROJECT_REQUEST,
	DESTROY_PROJECT_SUCCESS,
	DESTROY_PROJECT_ERROR,
} from './actionTypes';


const singleObj = {
	loading: false,
	data: {},
	errors: {},
	success: false
}

const initialState = {
	options: { ...singleObj, data: [] },
	list: { ...singleObj },
	original: { ...singleObj },
	detail: { ...singleObj },
	edit: { ...singleObj },
	remove: { ...singleObj },
}


const projects = (state = initialState, action) => {
	switch (action.type) {
		/*----------------------------------------------------------------------
		| GET ALL
		----------------------------------------------------------------------*/
		case GET_ALL_PROJECTS_REQUEST:
			state = {
				...initialState,
				list: {
					...state.list,
					loading: true,
					success: false,
				}
			}
			break;
		case GET_ALL_PROJECTS_SUCCESS:
			state = {
				...state,
				list: {
					...state.list,
					data: action.payload,
					loading: false,
					success: true,
				}
			}
			break;
		case GET_ALL_PROJECTS_ERROR:
			state = {
				...state,
				list: {
					...state.list,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;

		/*----------------------------------------------------------------------
		| GET ALL OPTIONS
		----------------------------------------------------------------------*/
		case GET_ALL_OPTIONS_PROJECTS_REQUEST:
			state = {
				...state,
				options: {
					...state.options,
					loading: true,
					success: false,
				}
			}
			break;
		case GET_ALL_OPTIONS_PROJECTS_SUCCESS:
			state = {
				...state,
				options: {
					...state.options,
					data: action.payload,
					loading: false,
					success: true,
				}
			}
			break;
		case GET_ALL_OPTIONS_PROJECTS_ERROR:
			state = {
				...state,
				options: {
					...state.options,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;

		/*----------------------------------------------------------------------
		| CREATE
		----------------------------------------------------------------------*/
		case CREATE_PROJECT_REQUEST:
			state = {
				...state,
				original: {
					...state.original,
					loading: true,
					success: false,
				}
			}
			break;
		case CREATE_PROJECT_SUCCESS:
			state = {
				...state,
				original: {
					...state.original,
					loading: false,
					data: action.payload,
					success: true,
				}
			}
			break;
		case CREATE_PROJECT_ERROR:
			state = {
				...state,
				original: {
					...state.original,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;

		/*----------------------------------------------------------------------
		| RETRIEVE
		----------------------------------------------------------------------*/
		case RETRIEVE_PROJECT_REQUEST:
			state = {
				...state,
				detail: {
					...state.detail,
					loading: true,
					success: false,
				}
			}
			break;
		case RETRIEVE_PROJECT_SUCCESS:
			state = {
				...state,
				detail: {
					...state.detail,
					loading: false,
					data: action.payload,
					success: true,
				}
			}
			break;
		case RETRIEVE_PROJECT_ERROR:
			state = {
				...state,
				detail: {
					...state.detail,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;

		/*----------------------------------------------------------------------
		| UPDATE
		----------------------------------------------------------------------*/
		case UPDATE_PROJECT_REQUEST:
			state = {
				...state,
				edit: {
					...state.edit,
					loading: true,
					success: false,
				}
			}
			break;
		case UPDATE_PROJECT_SUCCESS:
			state = {
				...state,
				edit: {
					...state.edit,
					loading: false,
					data: action.payload,
					success: true,
				}
			}
			break;
		case UPDATE_PROJECT_ERROR:
			state = {
				...state,
				edit: {
					...state.edit,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;

		/*----------------------------------------------------------------------
		| DESTROY
		----------------------------------------------------------------------*/
		case DESTROY_PROJECT_REQUEST:
			state = {
				...state,
				remove: {
					...state.remove,
					loading: true,
					success: false,
				}
			}
			break;
		case DESTROY_PROJECT_SUCCESS:
			state = {
				...state,
				remove: {
					...state.remove,
					loading: false,
					success: true,
				}
			}
			break;
		case DESTROY_PROJECT_ERROR:
			state = {
				...state,
				remove: {
					...state.remove,
					loading: false,
					errors: action.payload,
					success: false,
				}
			}
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
}

export default projects;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    Form,
    Input,
    FormText,
} from "reactstrap";


// actions
import authActions from "../../store/auth/actions"

// utils
import { getErrors } from '../../helpers/utils'

// components
import { IconLoading } from '../../components/Icons'

// import images
import Oycos_Logo from "../../assets/images/Oycos_Logo.png"
import BG_Ilustracion from "../../assets/images/BG_Ilustracion.png"
import "../../assets/css/login.css"


let Login = props => {
    const { register, handleSubmit, errors } = useForm()

    const {
        history,
        login,
        loading,
        data: { auth_token }
    } = props

    const err = getErrors(props.errors)
    const totalErrors = Object.keys(errors).length + err.totalErrors

    document.body.style.backgroundColor = "#330099"

    useEffect(() => {
        if (auth_token) {
            history.push("/encuestas")
        }
    }, [auth_token, history])

    return (
        <div className="login">
            <div className="wrapper">
                <div className="container">
                    <div className="login-logo">
                        <img src={Oycos_Logo} alt="logo-oycos" />
                    </div>
                    <div className="featured">
                        <img src={BG_Ilustracion} className="primary" alt="" />
                        <div className="secondary"></div>
                    </div>

                    <Form
                        className={"login-form needs-validation " + (totalErrors ? " was-validated" : "")}
                        onSubmit={handleSubmit(login)}
                        noValidate
                    >
                        {err.generalErrors &&
                            err.generalErrors.map((error, key) =>
                                <FormText className='mt-4 font-size-16 text-center' color="danger">
                                    {error.message}
                                </FormText>
                            )
                        }
                        <h1>Iniciar sesión</h1>
                        <div className="form-group">
                            <Input
                                id="username"
                                name="username"
                                type="text"
                                // className="form-control"
                                placeholder="Nombre de usuario"
                                required
                                innerRef={register({ required: true })}
                            />
                            {errors.username && errors.username.type === 'required' && (
                                <FormText color="danger">
                                    Este campo es obligatorio
                                </FormText>
                            )}
                        </div>
                        <div className="form-group">
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                // className="form-control"
                                placeholder="Contraseña"
                                required
                                innerRef={register({ required: true })}
                            />
                            {errors.password && errors.password.type === 'required' && (
                                <FormText color="danger">
                                    Este campo es obligatorio
                                </FormText>
                            )}
                        </div>
                        <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-oycos btn-lg btn-block"
                        >
                            {loading && <IconLoading />} Entrar
                        </button>
                    </Form>
                    <div className="copy">Oycos - consultores estratégicos 2021</div>
                </div>
            </div>
        </div>
    );
}

const mapStatetoProps = state => {
    const {
        loading,
        errors,
        data,
    } = state.auth

    return {
        loading,
        errors,
        data,
    }
}

export default withRouter(connect(mapStatetoProps, {
    login: authActions.loginRequest
})(Login));


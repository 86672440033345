import React, { useEffect } from 'react'
import _ from "lodash"
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
    Row,
    Col,
    Container,
    Table,
    Button
} from "reactstrap"
import Moment from "react-moment"

// actions
import projectsActions from "../../store/projects/actions"

// helpers
import { DATE_TIME_FMT } from "../../helpers/dates"

// components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { IconBigLoading } from "../../components/Icons"


export let Projects = props => {

    const {
        getAll,
        data,
        loading,
    } = props

    useEffect(() => {
        getAll()
    }, [getAll])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Inicio" breadcrumbItem="Encuestas" />

                    <Row>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "100px" }}>#</th>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Descripción</th>
                                                <th scope="col">Fecha de creación</th>
                                                <th scope="col">Finaliza</th>
                                                <th scope="col">Registrados</th>
                                                <th scope="col">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? <tr><td colSpan="7"><IconBigLoading /></td></tr> :
                                                _.get(data, "results", []).map(row => (
                                                    <tr key={row.id}>
                                                        <td>{row.logo}</td>
                                                        <td>
                                                            <h5 className="font-size-14">
                                                                <Link to={`/encuestas/${row.slug}`} className="text-dark">{row.name}</Link>
                                                            </h5>
                                                        </td>
                                                        <td>
                                                            <p className="text-truncate text-muted mb-0">{row.short_description}</p>
                                                        </td>
                                                        <td>
                                                            <Moment format={DATE_TIME_FMT}>
                                                                {row.epicollect_created_at}
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            <Moment format={DATE_TIME_FMT}>
                                                                {row.finish_at}
                                                            </Moment>
                                                        </td>
                                                        <td>
                                                            {row.total_entries}
                                                        </td>
                                                        <td>
                                                            <Link to={`/encuestas/${row.slug}`}>
                                                                <Button color="dark" size="sm">
                                                                    <i className="fa fa-eye" />
                                                                </Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}

const mapStateToProps = state => {
    const {
        projects: {
            list: {
                data,
                loading,
                errors
            }
        }
    } = state

    return {
        data,
        loading,
        errors
    }
}

Projects = withRouter(connect(mapStateToProps, {
    getAll: projectsActions.getAllRequest,
})(Projects));

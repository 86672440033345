import { API } from '../api'

const path = "/projects"


const getAll = params => {
    return API.get(path, {params})
        .then(response => response)
        .catch(error => {
            throw error
        })
}

const getAllOptions = params => {
    return API.get(`${path}/all`, { params })
        .then(response => response)
        .catch(error => {
            throw error
        })
}

const create = data => {
    return API.post(path, data)
        .then(response => response)
        .catch(error => {
            throw error
        })
}

const retrieve = id => {
    return API.get(`${path}/${id}`)
        .then(response => response)
        .catch(error => {
            throw error
        })
}

const update = (id, data) => {
    return API.patch(`${path}/${id}`, data)
        .then(response => response)
        .catch(error => {
            throw error
        })
}

const destroy = id => {
    return API.delete(`${path}/${id}`)
        .then(response => response)
        .catch(error => {
            throw error
        })
}


const programaServices = {
    getAll,
    getAllOptions,
    create,
    retrieve,
    update,
    destroy,
}

export default programaServices

import {
	GET_ALL_PROJECTS_REQUEST,
	GET_ALL_PROJECTS_SUCCESS,
	GET_ALL_PROJECTS_ERROR,

	GET_ALL_OPTIONS_PROJECTS_ERROR,
	GET_ALL_OPTIONS_PROJECTS_REQUEST,
	GET_ALL_OPTIONS_PROJECTS_SUCCESS,

	CREATE_PROJECT_REQUEST,
	CREATE_PROJECT_SUCCESS,
	CREATE_PROJECT_ERROR,

	RETRIEVE_PROJECT_REQUEST,
	RETRIEVE_PROJECT_SUCCESS,
	RETRIEVE_PROJECT_ERROR,

	UPDATE_PROJECT_REQUEST,
	UPDATE_PROJECT_SUCCESS,
	UPDATE_PROJECT_ERROR,

	DESTROY_PROJECT_REQUEST,
	DESTROY_PROJECT_SUCCESS,
	DESTROY_PROJECT_ERROR,
} from "./actionTypes";


/*----------------------------------------------------------------------
| GET ALL
----------------------------------------------------------------------*/
const getAllRequest = params => ({
	type: GET_ALL_PROJECTS_REQUEST,
	payload: params
})

const getAllSuccess = data => ({
	type: GET_ALL_PROJECTS_SUCCESS,
	payload: data
})

const getAllError = errors => ({
	type: GET_ALL_PROJECTS_ERROR,
	payload: errors
})


/*----------------------------------------------------------------------
| GET ALL OPTIONS
----------------------------------------------------------------------*/
const getAllOptionsRequest = params => ({
	type: GET_ALL_OPTIONS_PROJECTS_REQUEST,
	payload: params
})

const getAllOptionsSuccess = data => ({
	type: GET_ALL_OPTIONS_PROJECTS_SUCCESS,
	payload: data
})

const getAllOptionsError = errors => ({
	type: GET_ALL_OPTIONS_PROJECTS_ERROR,
	payload: errors
})

/*----------------------------------------------------------------------
| CREATE
----------------------------------------------------------------------*/
const createRequest = data => ({
	type: CREATE_PROJECT_REQUEST,
	payload: data
})

const createSuccess = data => ({
	type: CREATE_PROJECT_SUCCESS,
	payload: data
})

const createError = errors => ({
	type: CREATE_PROJECT_ERROR,
	payload: errors
})

/*----------------------------------------------------------------------
| RETRIEVE
----------------------------------------------------------------------*/
const retrieveRequest = id => ({
	type: RETRIEVE_PROJECT_REQUEST,
	payload: id
})

const retrieveSuccess = data => ({
	type: RETRIEVE_PROJECT_SUCCESS,
	payload: data
})

const retrieveError = errors => ({
	type: RETRIEVE_PROJECT_ERROR,
	payload: errors
})

/*----------------------------------------------------------------------
| UPDATE
----------------------------------------------------------------------*/
const updateRequest = (id, data) => ({
	type: UPDATE_PROJECT_REQUEST,
	payload: { id, data }
})

const updateSuccess = data => ({
	type: UPDATE_PROJECT_SUCCESS,
	payload: data
})

const updateError = errors => ({
	type: UPDATE_PROJECT_ERROR,
	payload: errors
})

/*----------------------------------------------------------------------
| DESTROY
----------------------------------------------------------------------*/
const destroyRequest = id => ({
	type: DESTROY_PROJECT_REQUEST,
	payload: id
})

const destroySuccess = () => ({
	type: DESTROY_PROJECT_SUCCESS,
})

const destroyError = errors => ({
	type: DESTROY_PROJECT_ERROR,
	payload: errors
})


const actions = {
	getAllRequest,
	getAllSuccess,
	getAllError,

	getAllOptionsRequest,
	getAllOptionsSuccess,
	getAllOptionsError,

	createRequest,
	createSuccess,
	createError,

	retrieveRequest,
	retrieveSuccess,
	retrieveError,

	updateRequest,
	updateSuccess,
	updateError,

	destroyRequest,
	destroySuccess,
	destroyError,
}
export default actions

import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Projects
import { Projects, ProjectDetail } from "../pages/Projects"


const authProtectedRoutes = [
	{ path: "/encuestas/:slug", exact: true, component: () => <ProjectDetail /> },
	{ path: "/encuestas", exact: true, component: () => <Projects /> },
	{ path: "/", component: () => <Redirect to="/login" /> },
];

const authRoutes = [
	{ path: "/login", component: Login },
	{ path: "/logout", component: Logout },
]

const publicRoutes = [

	// this route should be at the end of all other routes
];

export { authRoutes, authProtectedRoutes, publicRoutes };
